/* Reset styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Roboto', sans-serif;
  color: #ffffff;
  overflow: hidden;
}

.App {
  position: relative;
  text-align: center;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

/* Background with isolated filter and parallax effect */
.background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  will-change: transform; /* Optimization for parallax */
}

.content {
  max-width: 500px;
  text-align: center;
  padding: 2rem;
  background-color: rgba(0, 0, 0, 0.7); /* Darker background for a more polished look */
  border-radius: 12px;
  backdrop-filter: blur(10px);
  z-index: 1;
}

.title {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  font-weight: bold;
  color: #00e6b8; /* Neon teal color */
}

.description {
  font-size: 1.2rem;
  line-height: 1.5;
  margin-bottom: 2rem;
  color: #e0e0e0; /* Light grey for a softer look */
}

.mint-button {
  display: inline-block;
  font-size: 1.1rem;
  padding: 0.75rem 2rem;
  color: #ffffff;
  background-color: #00e6b8; /* Green color matching the title */
  border: none;
  border-radius: 8px;
  text-decoration: none;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.mint-button:hover {
  background-color: #00ffb3;
  transform: scale(1.05);
}

.links {
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  gap: 1.5rem;
}

.social-link {
  font-size: 1rem;
  color: #00e6b8; /* Same teal as title */
  text-decoration: none;
  transition: color 0.3s ease;
}

.social-link:hover {
  color: #66ffcc; /* Lighter teal for hover effect */
}

/* Mobile Responsive Adjustments */
@media (max-width: 600px) {
  .content {
    max-width: 90%;
    padding: 1.5rem;
  }
  .title {
    font-size: 2rem;
  }
  .description {
    font-size: 1rem;
  }
  .mint-button {
    font-size: 1rem;
    padding: 0.5rem 1.5rem;
  }
  .social-link {
    font-size: 0.9rem;
  }
}
